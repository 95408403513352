@import 'lib/core/styles/variables.module';
@import 'node_modules/@tabler/icons-webfont/tabler-icons.scss';

.container {
  transition: max-width 300ms linear, width 300ms linear, flex-basis 300ms linear, opacity 300ms linear;

  .libImageContainer {
    transition: max-width 300ms linear, opacity 300ms linear;

    .label-content {
      min-height: 2.5em;
      cursor: text;
    }

    .content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 300ms linear, opacity 300ms linear;

      .image-content {
        box-shadow: $box-shadow-light;
        transition: box-shadow 150ms linear;
        border-radius: $border-radius;

        &.selected {
          outline: solid 3px $primary-color;
          outline-offset: -3px;
          position: relative;

          &:after {
            font-family: tabler-icons, sans-serif;
            position: absolute;
            content: $ti-icon-circle-check-filled;
            color: $primary-color;
            font-size: 2em;
            line-height: 1em;
            top: .25em;
            right: .25em;
          }
        }

        &.disabled img {
          opacity: 0.5;
          cursor: not-allowed;
        }

        .image {
          border-radius: $border-radius;
          cursor: pointer;
        }

        &:hover {
          box-shadow: 0 0 .5em rgba($primary-color, 70%);
        }
      }
    }

    .button {
      display: none;
    }

    &:not(.active) {
      .content {
        padding: 0;
      }
    }

    &.active {

      .label-content {
        min-height: 0;
        padding: 0;
      }

      .content {
        max-height: 450px;
        overflow: auto;

        @media (max-width: $breakpoint-md + px) {
          max-height: 250px;
        }
      }

      .button {
        display: flex;
      }
    }
  }
}