@import 'lib/core/styles/variables.module';

.container {
  height: 100vh;

  & .MuiDrawer-paper {
    border-right: none;
    background-color: $background-form-color;
    top: $header-height;
    justify-content: space-between;
    height: calc(100vh - #{$header-height});
    width: calc(var(--left-side-width));
    left: 0;
    padding-left: 1em;
    overflow-x: hidden;

    @media (max-width: $breakpoint-lg + px) {
      top: 0;
      height: 100vh;
      padding-right: 1em;
    }
  }

  .content {
    padding: 0;
    width: 100%;
  }
  .footer {
    padding-bottom: 1em;
    text-align: center;
  }
}
