.cardSimple-container {
  background-color: var(--cardSimpleUI-background-color);
  border-radius: var(--cardSimpleUI-border-radius);
  box-shadow: var(--cardSimpleUI-box-shadow);
}

.cardSimple-paragraph  {
  width: 60%;
  color: grey;
  font-size: var(--cardSimpleUI-font-size)
}

.cardSimple-container,
.cardSimple-paragraph {
  text-align: center;
  padding: var(--cardSimpleUI-padding);
  margin: 0 auto;
  background-color: var(--cardSimpleUI-background-color);
}