@import 'lib/core/styles/variables.module';

.container {

  .content {
    height: 100%;
    padding-left: 1px;

    .field {
      input {
        background: transparent;
        border: none;
        height: 100%;
        padding: .6em 1em .5em;
        color: transparent;
      }

      fieldset {
        border: none;
        border-radius: 0;
      }
    }

    .endAdornment > div {
      padding-right: 0;
      text-align: center;
    }
  }

  &[data-focus=true] .content .field {
    input {
      color: initial;
    }

    &.min fieldset {
      border-right: 1px solid rgba($dark-color, 0.23);
    }
  }

  &[data-active=true] .content {
    padding-left: 0;

    .field.min fieldset {
      border-right: 2px solid $primary-color;
    }
  }
}