@import 'lib/core/styles/variables.module';

.ratingContainer {

  .ratingClear {
    cursor: pointer;
  }

  .fieldset {
    border-radius: .3em;
    text-align: left;
    margin: -.5em 0 0;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-height: 4em;
    border-color: rgba(0, 0, 0, 0.23);
    box-shadow: $box-shadow-light2;

    legend {
      font-size: .7em;
    }
  }

  .field {
    input {
      background: transparent;
      border: none;
      height: 100%;
      padding: 0;
    }

    fieldset {
      border: none;
      border-radius: 0;
    }
  }
}