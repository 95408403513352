@import 'lib/core/styles/variables.module';

.container {
  @-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }

  .spin {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .content-label {
    padding: 0 .565em;
    margin-top: -.375em;
    position: relative;

    .label {
      margin: 0;
      padding: 7.5px 4px 7.5px 5px;
      width: 100%;
      background: transparent;
      border: none;
      outline: none;
    }

    .state {
      position: absolute;
      right: .5em;
      top: .35em;
    }
  }

  .field_container {
    display: none !important;
    .field {
      input {
        background: transparent;
        border: none;
        height: 100%;
        padding: 0;
      }

      fieldset {
        border: none;
        border-radius: 0;
      }
    }
  }
}