@import 'lib/core/styles/variables.module';

.container {
  position: relative;

  .label {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    padding: 0;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 0;
    top: 0;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: auto;
    color: #004AAD;
    cursor: inherit;

    max-width: calc(100% - 24px);
    transform: translate(14px, 1.5em) scale(1);
  }

  .fieldset {
    border-radius: .3em;
    text-align: left;
    margin: -.5em 0 0;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-height: 4em;
    border-color: rgba($dark-color, 0.23);
    box-shadow: $box-shadow-light2;
    padding: 0;

    &:hover {
      border-color: $dark-color;
    }

    .field {
      height: 100%;
      transition: opacity 300ms linear;
    }

    .legend {
      float: unset;
      width: auto;
      overflow: hidden;
      display: block;
      padding: 0;
      font-size: 0.75em;
      visibility: hidden;
      max-width: 0.01px;
      transition: max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      white-space: nowrap;
      margin-left: .75em;

      span {
        padding-left: 5px;
        padding-right: 5px;
        display: inline-block;
        opacity: 0;
        visibility: visible;
      }
    }
  }

  &[data-focus="true"] {
    .fieldset .legend {
      max-width: 100%;
      transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
    }
    .label {
      max-width: calc(133% - 24px);
      transform: translate(14px, 0px) scale(0.75);
      user-select: none;
    }
  }
  &[data-focus="false"] {
    .fieldset .field {
      opacity: 0;
    }
  }

  &[data-active="true"] .fieldset {
    border: 2px solid $primary-color;
  }
}