@import 'lib/core/styles/variables.module';

.container {
  background-color: white !important;
  box-shadow: $box-shadow-light !important;
  margin-top: 1em !important;
  margin-left: 1em !important;
  border-radius: $border-radius;
  padding: .5em 0 .5em 1em !important;

  @media (max-width: $breakpoint-lg + px) {
    margin-left: 0 !important;
  }

  .left,
  .right {
    display: flex;
    align-items: center;
  }
  .right > button {
    margin: 0;
    min-width: initial;

    &:last-child {
      margin-right: .5em;
    }
  }
  .drawerHeader {
    margin: 0;
    padding: 0 0.5em 0 0;

    @media (min-width: $breakpoint-lg + px) {
      display: none;
    }
  }
}