@import 'lib/core/styles/variables.module';

.popper a {
  text-transform: initial;
}

.menu-button {
  text-transform: initial !important;
  font-size: 1em !important;
  font-weight: bold !important;

  svg {
    height: 2em;
    width: 2em;
    margin-right: .3em;
  }

  @media (max-width: $breakpoint-md + px) {
    margin-left: 0 !important;
  }
}

.menu-content {
  z-index: 1200;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba($primary-color, 0.32);
  font-size: 1.25em;

  li {
    font-size: 1em;

    svg {
      height: 1.2em;
      width: 1.2em;
      margin-right: .5em;
    }

    a {
      color: initial;
    }
    &:hover a {
      color: $primary-color;
    }
  }
}

.link-container {
  width: 100%;
  text-decoration: none;

  .link-content {
    display: flex;
    align-items: center;
  }
}
