@import 'lib/core/styles/variables.module';

.header {
  position: sticky;
  top: 0;
  z-index: 100;
  height: $header-height;
  //margin-bottom: 1em !important;
  background-color: $background-form-color;
  align-items: center !important;
  margin: 0 1em 1em !important;

  .logo {
    margin-top: 1em;
    width: calc($leftside-width - 1em);
    text-align: center;

    @media (max-width: $breakpoint-lg + px) {
      display: none;
    }
  }
  .middleHeader {

  }
  .rightHeader {

  }
}