@import 'lib/core/styles/variables.module';

.textPrimaryColorLabel {
  color: $primary-color;
}

.fieldProps {
  width: 100%;
  display: flex !important;

  .textPrimaryColorLabel {
    color: $primary-color;
  }

  .endAdornment > div {
    padding-right: 0 !important;
    text-align: center;
  }
}

.textInputProps {
  box-shadow: $box-shadow-input;
  background-color: white;
  width: 100%;
  border-radius: 4px;
  font-weight: bold;
  color: $primary-color;
  text-align: left;
}

.helperText {
  margin-right: 1em !important;
  margin-left: 1em !important;
}

.error {
  p, legend, svg, label {
    color: $error-color !important;
  }

  fieldset {
    border-color: $error-color !important;
  }
}
