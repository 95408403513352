@import 'lib/core/styles/variables.module';

$dpe: (
    "none-dpe": grey,
    "a": #008100,
    "b": #2acf1d,
    "c": #cbfe01,
    "d": #feff00,
    "e": #ffcd00,
    "f": #ff9b23,
    "g": #fe0000
);

$ges: (
    "none-ges": grey,
    "a": #f6edfe,
    "b": #e1c2fb,
    "c": #d6a7f8,
    "d": #cc93f4,
    "e": #ba6cf3,
    "f": #a746ee,
    "g": #8d00e3
);

.date-field {
  border-color: rgba(0, 0, 0, 0.23);
}

.energy-title {
  white-space: nowrap;
  text-align: left;
}

.energy-values {
  position: static;
  background-color: white
}

.checkbox-container {
  width: 100%;

  .checkbox-content {
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: $primary-color;
    background-color: white;
    box-shadow: $box-shadow-input;
    font-weight: bold;
    padding: .319em;
    border-radius: 4px;
    width: 100%;
    min-height: 1.4375em;
    margin: 0 !important;

    > span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .checkbox {
      padding: .5em;
    }
  }
}

.textArea {
  margin-bottom: 1em;
  text-align: center;
}

.textProps {
  border-radius: 4px;
}

.textInputDateProps {
  width: 100%;
  box-shadow: $box-shadow-input;
  border-radius: 4px;
}

.textInputProps {
  box-shadow: $box-shadow-input;
  background-color: white;
  width: 100%;
  border-radius: 4px;
  font-weight: bold;
  color: $primary-color;
  text-align: left;
}

.textPrimaryColorLabel {
  color: $primary-color;
}

.fieldProps {
  width: 100%;

  .textPrimaryColorLabel {
    color: $primary-color;
  }
}

.fieldset {
  border-radius: .3em;
  text-align: left;
  margin: -.5em 0 0;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-height: 4em;
  border-color: rgba(0, 0, 0, 0.23);
  box-shadow: $box-shadow-light2;

  legend {
    font-size: .7em;
  }
}

.error {
  p, legend, svg {
    color: $error-color !important;
  }

  fieldset {
    border-color: $error-color !important;
  }
}

.helperText {
  margin-right: 1em !important;
  margin-left: 1em !important;
}

.ratingContainer {
  display: flex;
}
.ratingClear {
  cursor: pointer;
}

.selectInputLabelProps,
.textInputPropsNested {
  padding-right: 2em !important;
}

.currencyInputProps .MuiOutlinedInput-notchedOutline {
  border: none;
}

@each $i-key, $i-value in $ges {
  .segment-ges-#{$i-key}-hide {
    display: none;
  }
  .segment-ges-#{$i-key} {
    display: flex;
    align-items: center;
    text-align: center;
    width: 200px;
    padding: 1em;
    cursor: pointer;

    &:hover {
      background-color: #d4dde7;
    }

    .label {
      margin-left: 32px;
      border-radius: 5px 0 0 5px;
      background-color: $i-value;
      width: 200px;
      height: 32.0px;
      color: white;
      text-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    }

    .arrow {
      fill: $i-value;
      stroke: white;
      stroke-width: 15;
    }

    .arrow-right {
      width: 0;
      height: 0;
      border-top: 1em solid transparent;
      border-bottom: 1em solid transparent;

      border-left: 1.5em solid $i-value;
    }
  }

  .segment-selected-ges-#{$i-key} {
    display: flex;
    align-items: center;
    text-align: center;
    width: 200px;
    cursor: pointer;
    padding: 1em;

    .label {
      margin-left: 32px;
      border-radius: 5px 0 0 5px;
      background-color: $i-value;
      width: 200px;
      height: 32.0px;
      color: white;
      text-shadow: black 0.1em 0.1em 0.2em
    }

    .arrow {
      fill: $i-value;
      stroke: white;
      stroke-width: 15;
      box-shadow: $box-shadow-input;
    }

    .arrow-right {
      width: 0;
      height: 0;
      border-top: 1em solid transparent;
      border-bottom: 1em solid transparent;

      border-left: 1.5em solid $i-value;
    }
  }
}


.segment-main-ges {
  display: flex;
  align-items: center;
  text-align: center;
  width: 400px;

  &:hover {
    background-color: $background-form-color;
  }

  .label {
    margin-left: 32px;
    border-radius: 5px 0 0 5px;
    background-color: $grey-color;
    width: 200px;
    height: 32.0px;
  }

  .arrow {
    fill: $grey-color;
    stroke: white;
    stroke-width: 15;
  }

  .arrow-right {
    width: 0;
    height: 0;
    border-top: 1em solid transparent;
    border-bottom: 1em solid transparent;

    border-left: 1.5em solid $grey-color;
  }
}

@each $i-key, $i-value in $dpe {
  .segment-dpe-#{$i-key}-hide {
    display: none;
  }
  .segment-dpe-#{$i-key} {
    display: flex;
    align-items: center;
    text-align: center;
    width: 200px;
    padding: 1em;
    cursor: pointer;

    &:hover {
      background-color: #d4dde7;
    }

    .label {
      margin-left: 32px;
      border-radius: 5px 0 0 5px;
      background-color: $i-value;
      width: 200px;
      height: 32px;
      color: white;
      text-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    }

    .arrow {
      fill: $i-value;
      stroke: white;
      stroke-width: 15;
    }

    .arrow-right {
      width: 0;
      height: 0;
      border-top: 1em solid transparent;
      border-bottom: 1em solid transparent;

      border-left: 1.5em solid $i-value;
    }
  }

  .segment-selected-dpe-#{$i-key} {
    display: flex;
    align-items: center;
    text-align: center;
    width: 200px;
    cursor: pointer;
    padding: 1em;

    .label {
      margin-left: 32px;
      border-radius: 5px 0 0 5px;

      background-color: $i-value;
      width: 200px;
      height: 32px;
      color: white;
      text-shadow: black 0.1em 0.1em 0.2em
    }

    .arrow {
      fill: $i-value;
      stroke: white;
      stroke-width: 15;
      box-shadow: $box-shadow-input;
    }

    .arrow-right {
      width: 0;
      height: 0;
      border-top: 1em solid transparent;
      border-bottom: 1em solid transparent;

      border-left: 1.5em solid $i-value;
    }
  }
}

.segment-main-dpe {
  display: flex;
  align-items: center;
  text-align: center;
  width: 400px;

  &:hover {
    background-color: #d4dde7;
  }

  .label {
    border-radius: 4px;
    background-color: $grey-color;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    border-top: 2px solid white;
    border-width: 50%;
    width: 200px;
    height: 32.0px;
  }

  .arrow {
    fill: $grey-color;
    stroke: white;
    stroke-width: 15;
  }
}

.container-password {

  &.margin {
    margin-bottom: 2em;
  }
  .content-password {
    width: 100%;
  }
}

.container-constraints {
  min-height: 1em;

  .constraints {
    display: inline-block;
    margin: 0;
    font-size: .75em;

    &.grey {
      color: $grey-color
    }

    &.green {
      color: green
    }

    .constraints-subdiv {
      display: flex;
      align-items: center;
      text-align: center;

      .constraints-p__icon {
        padding-top: 5px;
        margin: 0 .4em
      }
      p {
        margin: 0;
      }
    }
  }
}