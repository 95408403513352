@import 'lib/core/styles/variables.module';

.container {
  text-align: left;
  white-space: pre-wrap;
  position: relative;

  .title {
    text-align: center;
    color: $primary-color;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: .5em;
  }

  .context {
    margin-bottom: 2em;
  }

  .alert {
    margin: 0 auto 3em;
  }

  .success {
    display: flex;
    margin: 0 auto;
    align-items: center;

    .p {
      margin-left: 2em
    }
  }

  form {
    width: 90%;
  }
}
