$fontsize-base-3xl: 16px;
$fontsize-base-xxl: 14px;
$fontsize-base: 12px;

$primary-color: #004AAD;
$secondary-color: #A5C3FF;
$tertiary-color: #ECF2FE;
$dark-color: #060537;
$white-color: #FFFFFF;
$white-light-color: #F2F2F2;
$error-color: #EB1818;
$warning-color: #FF922E;
$info-color: #0288d1;
$success-color: #2E7D32;
$grey-color: #54595F;
$background-form-color: $tertiary-color;

$header-height: 5em;
$leftside-width: 16em;
$leftside-home-width: 50vw;
$box-shadow-primary: 0 0 1em rgba($primary-color, 32%);
$box-shadow-light: rgba($primary-color, 15%) 0 0.125rem 0.25rem -0.0625rem;
$box-shadow-light2: rgba($primary-color, 32%) 0 0.125rem 0.25rem -0.0625rem;
$box-shadow: 0;
$box-shadow-input: rgba($primary-color, 32%) 0 0.125rem 0.25rem -0.0625rem;
$border-radius: .5em;

$breakpoint-xs: 0;
$breakpoint-sm: 576;
$breakpoint-md: 768;
$breakpoint-lg: 992;
$breakpoint-xl: 1200;
$breakpoint-xxl: 1400;
$breakpoint-3xl: 1600;
$breakpoint-4xl: 1900;
$breakpoint-4k: 2200;

:export {
  fontSizeBase: $fontsize-base;
  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  tertiaryColor: $tertiary-color;
  darkColor: $dark-color;
  whiteColor: $white-color;
  errorColor: $error-color;
  warningColor: $warning-color;
  infoColor: $info-color;
  successColor: $success-color;
  greyColor: $grey-color;
  backgroundFormColor: $background-form-color;

  headerHeight: $header-height;
  leftSideWidth: $leftside-width;

  breakpointXS: $breakpoint-xs;
  breakpointSM: $breakpoint-sm;
  breakpointMD: $breakpoint-md;
  breakpointLG: $breakpoint-lg;
  breakpointXL: $breakpoint-xl;
  breakpointXXL: $breakpoint-xxl;
  breakpoint3XL: $breakpoint-3xl;
  breakpoint4XL: $breakpoint-4xl;
  breakpoint4k: $breakpoint-4k;
}
