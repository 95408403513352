@import 'lib/core/styles/variables.module';

.left-side-base {
  #{--left-side-width}: $leftside-width;
}

.left-side-home {
  #{--left-side-width}: $leftside-home-width;
}

.left-side-none {
  #{--left-side-width}: 0%;
}

.article {
  margin: 1em 1em 0 !important;

  .left-side {
    height: calc(100vh - #{$header-height} - 1em);
    overflow: hidden;
    width: var(--left-side-width);

    @media (max-width: $breakpoint-lg + px) {
      width: 0;
    }
  }

  .container {
    width: calc(100% - var(--left-side-width));

    box-shadow: none;
    border-radius: $border-radius;
    //margin-bottom: 1em;

    @media (max-width: $breakpoint-lg + px) {
      width: 100%;
    }

    .content {
      overflow: auto;

      > *:first-child {
        margin-top: 0;
      }

      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}