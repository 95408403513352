@import 'lib/core/styles/variables.module';

.menu {
  .icon {
    min-width: initial;
    margin-right: .3em;

    svg {
      height: 2em;
      width: 2em;
    }
  }
}


.link-container {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  vertical-align: middle;
  padding: 0 .5em;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }

  .label {
    color: $dark-color;
    font-weight: 700;
    display: inline-flex;
    align-items: center;

    svg {
      height: 2em;
      width: 2em;
      margin-right: .2em;
    }

    &:hover {
      color: $primary-color;
    }
  }
}